import {
    HemisphereLight,
    DirectionalLight,
    PointLight,
    AmbientLight,
    PointLightHelper
} from 'three';

const renderMultiplier = 1.5;

const ambiLight = new AmbientLight(0xd8f3ff, .85)

const hemiLight = new HemisphereLight(0xffffff, 0xffffff);
hemiLight.color.setHSL(0, 1, 1);
hemiLight.intensity = 1.4
hemiLight.groundColor.setHSL(0, 1, 1);
hemiLight.position.set(0, 50, 0);

const directLight = new DirectionalLight(0xFFFFFF); // 0xFFDC9E / FFEED1 production
directLight.intensity = 4
directLight.color.setHex(0xFFFFFF); // 0xFFDC9E / FFEED1 production
directLight.position.set(-4, 1.25, 2);
directLight.lookAt(0, 0, 2);
directLight.castShadow = true;
directLight.shadow.mapSize.width = 2048 * renderMultiplier;
directLight.shadow.mapSize.height = 2048 * renderMultiplier;
const d = 5
directLight.shadow.camera.left = - d;
directLight.shadow.camera.right = d;
directLight.shadow.camera.top = d;
directLight.shadow.camera.bottom = - d;
directLight.shadow.camera.far = 500;
directLight.shadow.camera.near = 0.05
directLight.shadow.normalBias = -0.0001; // -0.0001
directLight.shadow.autoUpdate = true;
directLight.shadow.radius = 5;
directLight.shadow.blurSamples = 24;
directLight.shadow.camera.updateProjectionMatrix();

const pointLight1 = new PointLight(0xffffff)
pointLight1.position.set(-1.97, 2.2, 0.815);
pointLight1.power = 0
pointLight1.castShadow = false;
directLight.shadow.mapSize.width = 2048 * renderMultiplier;
directLight.shadow.mapSize.height = 2048 * renderMultiplier;
const p = 100
pointLight1.shadow.camera.left = - p;
pointLight1.shadow.camera.right = p;
pointLight1.shadow.camera.top = p;
pointLight1.shadow.camera.bottom = - p;
pointLight1.shadow.camera.far = 500;
pointLight1.shadow.normalBias = -0.0001; // 0.0001
pointLight1.shadow.autoUpdate = true;
pointLight1.shadow.camera.updateProjectionMatrix();
const pointLightHelper1 = new PointLightHelper(pointLight1, 0.1);

const pointLight2 = pointLight1.clone()
pointLight2.position.set(-.2, 2.2, 0.815);
const pointLightHelper2 = new PointLightHelper(pointLight2, 0.1);

const pointLight3 = pointLight1.clone()
pointLight3.position.set(1.57, 2.2, 0.815);
const pointLightHelper3 = new PointLightHelper(pointLight3, 0.1);






export {
    hemiLight,
    directLight,
    pointLight1,
    pointLightHelper1,
    pointLight2,
    pointLightHelper2,
    pointLight3,
    pointLightHelper3,
    ambiLight
}